import React from 'react'
import {format} from 'date-fns'
import "../styles/festival-events.css"
import {Link} from 'gatsby'
import {getEventUrl} from '../lib/helpers'
import PortableText from './portableText'

import FestivalEventDateSingle from '../components/festival-event-date-single'

function FestivalEventDate (props) {
const {events} = props

return (

    <div className="group">

      {events && events.map((event, index) => (
        <FestivalEventDateSingle
          event={event}
        />
      ))}

    </div>

)}

export default FestivalEventDate