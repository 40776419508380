import React from 'react'
import {format} from 'date-fns'
import "../styles/festival-events.css"
import {Link} from 'gatsby'
import {getEventUrl, isInThePast} from '../lib/helpers'
import PortableText from './portableText'

function FestivalEventDateSingle (props) {
const {event} = props

var datepast = false
if (typeof window !== "undefined") {
  datepast = isInThePast( new Date(event.endDate) );
}

return (


        <div className="event-item grid">
          <div className="width-4-12-m width-4-12">
            <p>{format(new Date(event.beginDate), 'h:mm a')} – {format(new Date(event.endDate), 'h:mm a')}</p>
            {event.location && <p>{event.location}</p> }
            {!datepast && 
              <>{event.url && event.url.title && event.url.url && <p><a className="more" href={event.url.url}>{event.url.title}</a></p> }</>
            }
          </div>
          <div className="width-8-12-m width-8-12">
            <p className="event-title"><Link to={getEventUrl(event.festivalYear.title, event.slug.current)}>{event.title}</Link></p>
              <PortableText blocks={event._rawIntro} />
          </div>
        </div>

)}

export default FestivalEventDateSingle