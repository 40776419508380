import React from 'react'
import {format} from 'date-fns'
import "../styles/festival-events.css"
import {Link} from 'gatsby'

import FestivalEventDate from '../components/festival-event-date'

function FestivalEvents (props) {
const {events} = props

//console.log(events)

const eventsObj = events.reduce((eventsObj, event) => {
  const date = event.beginDate.split('T')[0];
  if (!eventsObj[date]) {
    eventsObj[date] = [];
  }
  eventsObj[date].push(event);
  return eventsObj;
}, {});


const eventsArr = Object.keys(eventsObj).map((date) => {
  return {
    date,
    events: eventsObj[date]
  };
});

console.log(eventsArr);


return (

  <div id="festival-events">
  <div className="wrapper">
  <div id="festival-events-inner">

    <h2>Events</h2>

      {eventsArr && eventsArr.map((eventDate, index) => (
        <div className="event-date-group">
          <p className="day">{format(new Date(eventDate.events[0].beginDate), 'MMMM d')}</p>
          <FestivalEventDate {...eventDate} />
        </div>
      ))}

  </div>
  </div>
  </div>

)}

export default FestivalEvents