import React from 'react'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
} from '../lib/helpers'
import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import FestivalEvents from '../components/festival-events'

function Events (props) {

const {data} = props
const site = (data || {}).site
const festival = (data || {}).festival

const events = (data || {}).events
    ? mapEdgesToNodes(data.events)
      .filter(filterOutDocsWithoutSlugs)
    : []

//console.log(events);

return (

  <Layout 
      title={site.title} 
      email={site.email} 
      footer={site.footer} 
      partners={festival.partners} 
      colorA={festival.colorA} 
      colorB={festival.colorB} 
      colorC={festival.colorC} 
      bodyFont={festival.bodyFont} 
      secondaryFont={festival.secondaryFont} 
      horizontalLogo={festival.horizontalLogo}
      instagramLink={site.instagramLink} 
      facebookLink={site.facebookLink} 
      twitter={site.twitter} 
      linkedInLink={site.linkedInLink} >
      <SEO
        title={site.title}
        author={site.author}
        description={site.description}
        keywords={site.keywords}
        image={festival.siteImage}
      />
    <Container>
      {events && (
        <FestivalEvents
          events={events}
        />
      )}
    </Container>
  </Layout>


)}

export default Events